<template>
    <div class="informationBox">
        <location :locationArr="locationArr"/>
        <!-- <title-tap v-if="typeList.length>0" :defaultActiveName="typeId" :activeTitle="typeList" @tabclick="tabclick"/> -->
        <!-- 领导关怀-->
        <div class="titleTap-box">
            <div class="care" @click="informationPar(item.newsId)" v-for="(item,index) in resData.records" :key="index">
                <img :src="item.titleImage">
                <div class="care-content">
                    <h5>{{item.newsTitle}}</h5>
                    <p>{{item.subContent}}</p>
                </div>
                <div class="care-time">
                    <h3>{{item.year}}</h3>
                    <p>{{item.monthAndDay}}</p>
                </div>
            </div>
            <el-pagination v-if="resData.total>0" style="text-align: right; margin:60px 0;"
                           background
                           prev-text="上一页"
                           next-text="下一页"
                           layout="prev, pager, next"
                           :current-page="page"
                           @current-change="currentChange"
                           :page-size=4
                           :total="resData.total">
            </el-pagination>
        </div>


    </div>
</template>

<script>
    // import titleTap from '@/components/titleTap.vue'
    import location from '@/components/location.vue'

    export default {
        name: 'information',
        components: {
            // titleTap,
            location
        },
        data() {
            return {
                typeId: '',
                typeList: [],
                resData: {},
                page: 1,
                show: true,
                locationArr: null
            }
        },
		created() {
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/mobile/information",
						query:{
							typeId:this.$route.query.typeId
						}
						
			        });
			}
		},
        mounted() {
            let typeId = this.$route.query.typeId;
            if ((typeId != null && typeId != '' && typeof (typeId) != 'undefined')) {
                this.typeId = typeId
                this.initLocation()
                this.getList();
            }

            // this.getNewsTypeList();
        },
        methods: {
            // tabclick(item) {
            //     const typeId = item.typeId
            //     if (typeId != this.typeId) {
            //         this.typeId = typeId
            //         const url = this.$route.path;
            //         this.$router.push({path: url, query: {typeId: typeId}})
            //     }
            // },
            // 表彰荣誉分页点击事件
            currentChange(page) {
                this.page = page
                this.getList()
            },
            // 跳转至详情页
            informationPar(id) {
                this.$router.push({name: 'informationDetail', query: {id: id, typeId: this.typeId}})
            },
            initLocation() {
                const params = {};
                params.typeId = this.typeId;
                this.$http.getNewsTypeName(params).then(res => {
                    if (res.code == 200) {
                        const locationArr = [];
                        locationArr.push({"title": res.data, "url": "/information?typeId=" + params.typeId})
                        this.locationArr = locationArr;
                    }
                })
            },
            // getNewsTypeList() {
            //     this.$http.newsTypeList().then(res => {
            //         if (res.code == '200') {
            //             this.typeList = res.data
            //             if (res.data.length > 0 && this.typeId == '') {
            //                 this.typeId = res.data[0].typeId
            //                 this.initLocation()
            //                 this.getList();
            //             }
            //         }
            //     })
            // },
            getList() {
                this.$http.newsList({typeId: this.typeId, page: this.page}).then(res => {
                    if (res.code == 200) {
                        this.resData = res.data
                    }
                })
            },
            refreshData() {
                let typeId = this.$route.query.typeId;
                if ((typeId != null && typeId != '' && typeof (typeId) != 'undefined')) {
                    this.typeId = typeId
                }
                this.resData = {}
                this.page = 1;
                this.initLocation()
                this.getList();
            }
        },
        watch: {
            $route: 'refreshData'
        }
    }
</script>
<style type="text/css">
    @import '~@/assets/css/points.css';
</style>
